"use client";
import Image from "next/image";
import React from "react";
import bagImages from "@/public/images/bags.png";
import { Button } from "@nextui-org/react";
const ShipInternational = () => {
  return (
    <section className="bg-[#F8F9FE] max-h-[458px] relative lg:flex justify-center overflow-hidden">
      <div className="absolute left-0 top-0 w-[50%] m-auto hidden lg:block">
        <Image src={bagImages} alt="woow"  className="w-[100%] max-h-[458px]"  />
       </div>
      <div className="container min-h-[458px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[2%] lg:gap-[10%]">
          <div className="order-first">
            <Image src={bagImages} alt="woow"  className="w-[100%] max-h-[458px] lg:hidden"  />
          </div>
          <div className="flex items-center justify-center px-4">
            <div className="lg:mt-14">
              <h5 className="text-[#1777FE] text-[14px] font-poppins">
                Ship international?
              </h5>
              <h3 className="heading3 text-[#17176A] relative title-down-border-sm lg:title-down-border after:w-[16%] lg:after:w-[24%]">
                Ready to shop <span className="text-gradient">US & UK</span> stores
              </h3>
              <p className="text-[12px] lg:text-[16px] text-[#333333] font-plus_jakarta font-medium my-3 lg:my-7">
                With a my US Address, shopping for your favorite brand in the US
                & UK just got easier. It takes a few moments to sign up!
                Learn how you can save more on international rates with
                us.
              </p>
              <Button
                size="lg"
                className="bg-[#1777FE] text-[12px] lg:text-[18px] h-[40px] lg:h-[48px] text-white font-plus_jakarta font-semibold
                lg:my-5 hover:bg-blue-600 uppercase rounded-[8px] lg:rounded-[12px]"
              >
                Explore
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipInternational;

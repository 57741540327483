export const SliderArrowLeft = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28" cy="28" r="28" fill="white" />
    <mask
      id="mask0_337_7243"
      maskUnits="userSpaceOnUse"
      x="11"
      y="11"
      width="34"
      height="34"
    >
      <rect x="11.2" y="11.2002" width="33.6" height="33.6" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_337_7243)">
      <path
        d="M33.6 42L19.6 28L33.6 14L36.085 16.485L24.57 28L36.085 39.515L33.6 42Z"
        fill="#C9C8C8"
      />
    </g>
  </svg>
);
export const SliderArrowRIght = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow right">
      <circle
        id="Ellipse 955"
        cx="28"
        cy="28"
        r="28"
        transform="matrix(-1 0 0 1 56 0)"
        fill="white"
      />
      <g id="arrow_back_ios_new">
        <mask
          id="mask0_337_7248"
          maskUnits="userSpaceOnUse"
          x="11"
          y="11"
          width="34"
          height="34"
        >
          <rect
            id="Bounding box"
            width="33.6"
            height="33.6"
            transform="matrix(-1 0 0 1 44.8 11.2002)"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_337_7248)">
          <path
            id="arrow_back_ios_new_2"
            d="M22.4003 42L36.4003 28L22.4003 14L19.9153 16.485L31.4303 28L19.9153 39.515L22.4003 42Z"
            fill="#C9C8C8"
          />
        </g>
      </g>
    </g>
  </svg>
);

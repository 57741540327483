"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { HiArrowUpRight } from "react-icons/hi2";
import roundBgGrayImg from "@/public/images/roundbg_gray.png";
import dashboardViewImage from "@/public/images/dashboard_view.png";
import ImageSlider from "@/components/ImageSlider/ImageSlider";
const ConnectYourNeeds = () => {
  const autoPlay = true;
  const [imageIndex, setImageIndex] = useState(0);

  const connectSliders = [
    {
      id: 1,
      shipment_name: "Cargo",
      des: "Usually, shipping is considered as the transport of cargo or fleet transportation through the sea. However, lately, the world has evolved, so have the logistics.",
      img: dashboardViewImage,
    },
    {
      id: 2,
      shipment_name: "Express",
      des: "The USA stands unmatched in the manufacturing, designing, and producing the ultimate best quality merchandise across the world. With home to the world’s sassiest brands, the US",
      img: dashboardViewImage,
    },
    {
      id: 3,
      shipment_name: "Shipping",
      des: "While everyone else gives up on you for internationalshipping. ​woow is here for the rescue! Shipping is aninternational trusted community marketplace for people to",
      img: dashboardViewImage,
    },
  ];
  useEffect(() => {
    const id = setInterval(() => {
      setImageIndex((imageIndex + 1) % connectSliders?.length);
    }, 4000);
    return () => clearInterval(id);
  }, [imageIndex, connectSliders?.length]);

  return (
    <section className="py-10 lg:py-[110px] bg-[#F5F5F5] relative overflow-hidden lg:overflow-visible">
      <div className="absolute -bottom-20 right-0 z-10">
        <Image src={roundBgGrayImg} alt="woow" className="hidden lg:block max-w-[150px]" />
      </div>
      <div className="container mx-auto bg-[#F8F9FE] rounded-[16px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[10%] rounded-[16px] py-10 px-5 items-start">
          <div className="order-first lg:order-last flex items-center my-auto">
            <div className="w-full h-full relative mbt-10">
              <div className="h-full w-full overflow-hidden  flex flex-shrink-0 flex-grow-0">
                {connectSliders.map((url) => (
                  <Image
                    key={url?.id}
                    src={url?.img}
                    className="image-slider-item"
                    alt="woow"
                    style={{ translate: `${-100 * imageIndex}%` }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="heading3 text-[#17176A] relative  title-down-border">
              Connect your <span className="text-gradient">needs</span>
            </h2>
            <div className="py-2 ">
              {connectSliders?.map((item: any,index) => (
                <div
                  key={item?.id}
                  onClick={()=>setImageIndex(index)}
                  className={`py-4 border-b-2 border-[#C9C8C8] relative connect-border-bottom  ${imageIndex === index ?'connect-item-active':''} cursor-pointer`}
                >
                  <h4 className="text-[#7755FF] text-[16px] lg:text-[28px] font-poppins font-medium">
                    {item?.shipment_name}
                  </h4>
                  <p className="text-[#545454] text-[11px] lg:text-[14px] font-plus_jakarta font-medium py-3">
                    {item?.des}
                  </p>
                  <a
                    href="#"
                    className="flex items-center gap-1 text-[12px] lg:text-[14px] font-medium text-blue-600 font-plus_jakarta"
                  >
                    Read more <HiArrowUpRight />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectYourNeeds;

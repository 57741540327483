import React from "react";

const SubscribtInputEmailIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1816 4H6.68164C4.4725 4 2.68164 5.79086 2.68164 8V17C2.68164 19.2091 4.4725 21 6.68164 21H17.1816C19.3908 21 21.1816 19.2091 21.1816 17V8C21.1816 5.79086 19.3908 4 17.1816 4Z"
          stroke="#999999"
          strokeWidth="1.5"
        />
        <path
          d="M2.72852 8.08984L9.93375 12.2199C10.5381 12.571 11.2236 12.7558 11.9214 12.7558C12.6193 12.7558 13.3048 12.571 13.9091 12.2199L21.1342 8.08984"
          stroke="#999999"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SubscribtInputEmailIcon;

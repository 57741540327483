"use client";
import React, { useRef } from "react";
import "swiper/css";
import { QuoteIcon } from "@/icons/website-icon/QuoteIcon";
import EllipseImg from "@/public/images/Ellipse.png";
import Image from "next/image";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderArrowLeft } from "@/icons/website-icon/SliderArrow";
import { SliderArrowRIght } from "@/icons/website-icon/SliderArrow";
const Testimonials = () => {
  const sliderRef = useRef<Slider>(null);

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    centerPadding: "40px",
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section className="py-10 lg:py-[110px] bg-[#E8F1FF]">
        <div className="container mx-auto">
          <div className="flex justify-center">
            <div>
              <h5 className="text-[#1777FE] text-[14px] font-poppins text-center">
                Testimonials
              </h5>
              <h3
                className="heading3 text-[#17176A] text-center
                  "
              >
                Clients <span className="text-gradient">success</span> is <br /> our success
              </h3>
            </div>
          </div>
          <div className="my-10 relative">
            <div>
              <Slider {...settings} ref={sliderRef}>
                {[1, 2, 3, 4, 5, 6, 7].map((item: any, index: number) => (
                  <div key={index}>
                    <div className="m-4 lg:m-3 p-3 xl:p-5 bg-white rounded-[10px]">
                      <div className="w-[80px] h-[80px] rounded-full relative">
                        <Image src={EllipseImg} alt="woow" />
                        <div className="absolute -top-1 -right-2">
                          <QuoteIcon />
                        </div>
                      </div>
                      <div className="my-4">
                        <article className="text-[#666666] text-[12px] xl:text-[14px] font-poppins py-2 px-3 prose lg:prose-xl prose-zinc border-l-4  border-blue-300">
                          Its good to have multiple shipping options, as we can
                          select which service is better in the given city.
                          overall, our parcel reach on time and our clients are
                          happy.
                        </article>
                        <h5 className="text-[#1D1D1F] text-[12px] lg:text-[14px] font-poppins font-medium mt-2">
                          Devon Lane
                        </h5>
                        <h6 className="text-[#1D1D1F] text-[12px] lg:text-[16px] font-plus_jakarta font-medium">
                          Greece
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              onClick={goToPrevSlide}
              className="hidden lg:flex items-center justify-between absolute top-[140px] -left-[50px] z-50 cursor-pointer"
            >
              <SliderArrowLeft />
            </div>

            <div
              onClick={goToNextSlide}
              className="hidden lg:flex items-center justify-between absolute top-[140px] -right-[52px] z-50 cursor-pointer"
            >
              <SliderArrowRIght />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;

"use client";
import React, { useState } from "react";
import Accordionitem from "./Accordionitem";
import subscribBg from "@/public/images/subscribe_bgImg.png";
import Image from "next/image";
import { Button, Input } from "@nextui-org/react";
import SubscriptInputEmailIcon from "@/icons/website-icon/SubscribtInputEmailIcon";
import { SubscribeRltArrorIcon } from "@/icons/website-icon/SubscribeLeftRightArrowIcon";
const accordionData = [
  {
    id: 1,
    title: "What is woowbd.com?",
    description:
      "Discover woowbd.com, your premier online destination offering a diverse range of products, including clothing, electronics, accessories, and more, conveniently available for doorstep delivery.",
  },
  {
    id: 2,
    title: "How do I place an order on woowbd.com?",
    description:
      "With wOOw shipping panel you get access to ship from Bangladesh, USA, India, China to rest of the world. For your business needs or Even your personal needs.",
  },
  {
    id: 3,
    title: "Does woowbd.com offer international shipping?",
    description: "Yes. You can buy anything from anywhere.",
  },
  {
    id: 4,
    title: "What payment methods does woowbd.com accept?",
    description:
      "Bank Transfer, Bkash, Rocket, Nagad, Visa Card, Master Card, Credit Card and all kinds of authorized payment methods we accept.",
  },
  {
    id: 5,
    title: "How long will it take to receive my order from woowbd.com?",
    description:
      "Bank Transfer, Bkash, Rocket, Nagad, Visa Card, Master Card, Credit Card and all kinds of authorized payment methods we accept.",
  },
  {
    id: 6,
    title: "Can I return or exchange items purchased from woowbd.com?",
    description:
      "Bank Transfer, Bkash, Rocket, Nagad, Visa Card, Master Card, Credit Card and all kinds of authorized payment methods we accept.",
  },
];
const Faq = () => {
  const [open, setOpen] = useState<number>(0);
  const toggle = (index: any) => {
    if (open === index) {
      return setOpen(0);
    }
    setOpen(index);
  };
  return (
    <>
      <section className="py-10 lg:py-[150px] bg-gradient-to-br from-[#9ee5f538] via-white to-white">
        <div className="container">
          <div className="text-center">
            <h5 className="text-[#1777FE] text-[14px] font-poppins">
              Find answer
            </h5>
            <h2 className="heading3 bg-gradient-to-r text-gradient inline-block my-4">
              Frequently Asked Questions
            </h2>
          </div>

          <div className="py-2 max-w-[700px] relative z-30 lg:mx-auto">
            {accordionData.map((item: any, index: number) => (
              <Accordionitem
                key={item?.id}
                open={open === index}
                title={item?.title}
                description={item?.description}
                toggle={() => toggle(index)}
              />
            ))}
          </div>
          <div
            className="mt-[150px] min-h-[250px] lg:rounded-[20px] 
          bg-gradient-to-br from-[#f1b0e7] via-[#C6E3FF] to-[#b0d5f8]
           lg:shadow-lg relative z-10"
          >
            <div className="absolute top-0 left-0 right-0 -z-10">
              <Image
                src={subscribBg}
                alt="woow"
                objectFit="cover"
                className="w-full h-full hidden lg:block"
              />
            </div>
            <div className="flex justify-center">
              <div className="my-5 lg:mx-5">
                <h2 className="heading3 text-[#125FCB]  text-center my-3">
                  Want Us To Contact?
                </h2>
                <p className="text-[#545454] font-plus_jakarta font-medium text-[11px] lg:text-[14px] text-center">
                  Send us your email address so that we can contact & <br className="lg:hidden" /> discuss
                  about your needs.
                </p>
                <div className="flex px-5 lg:px-0">
                  <div
                    className="bg-white rounded-[8px] flex items-center gap-[0px] border-1
                 border-[#EEEEEE] min-w-[350px] lg:min-w-[446px] min-h-[52px] mt-5"
                  >
                    <Input
                      type="email"
                      variant="bordered"
                      placeholder="Enter your email"
                      classNames={{
                        label: [
                          "text-[17px] font-plus_jakarta text-[#596066] font-semibold",
                        ],
                        input: [
                          "bg-transparent",
                          "text-[15px] font-inter text-[#AEAEAE] font-medium",
                          "placeholder:text-[12px] placeholder:font-plus_jakarta placeholder:text-[#999999]",
                        ],

                        inputWrapper: ["border-none shadow-none"],
                      }}
                      startContent={
                        <div>
                          <SubscriptInputEmailIcon />
                        </div>
                      }
                    />
                    <Button
                      size="lg"
                      className="bg-[#125FCB] text-white rounded-[6px] text-[14px] lg:text-[20px] 
                   font-inter text-center w-[155px] my-1 mx-1"
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;

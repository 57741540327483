import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/GotoTop/GotoTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ShippingRateCalculator/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/website/layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/website/HomePage/Faq/Faq.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/website/HomePage/Services/ConnectYourNeeds.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/website/HomePage/Services/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/website/HomePage/ShipForMe/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/website/HomePage/ShipInternational/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/features/website/HomePage/Testimonials/Testimonials.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/adidas-logo-adidas-icon.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/airplane_cargo.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/amazon-logo-png-amazon-white.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/choose_us_bg.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/credit_card.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/dashboard.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/dhl_white.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/dot.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/ebay-logo-white.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/fedex_white_logo.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/hero_vector.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home_hero_img.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/location_track.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/map.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/pickup_box.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/plane_fly.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/roundbg_yellow.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/ship_online_orders.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/shipment_truck.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/shipping_car.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/shipping-shopping-imgs.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/shopping_box.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/ups-logo-white 1.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/vector_rectangle.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/vector_triangle1.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/walmart-logo-black-and-white.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/woow_yellow_logo.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/woowbox.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/world_city.png");

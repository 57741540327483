"use client";
import { TravelerIcon } from "@/icons/shipping/CourierIcon";
import { LearnBtnRightArrowIcon } from "@/icons/website-icon/ButtonArrowRight";
import CartIcon from "@/icons/website-icon/CartIcon";
import FastDeliveryIcon from "@/icons/website-icon/FastDeliveryIcon";
import PlayBtnIcon from "@/icons/website-icon/PlayBtnIcon";
import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import Image from "next/image";
import placeBgImg from "@/public/images/place_bg.svg";
import dynamic from "next/dynamic";
const MarketPlaces = dynamic(() => import("../MarketPlaces/MarketPlaces"));
const ShipAnyWhereAnimation = dynamic(
  () => import("../ShipAnyWhereAnimation"),
  {
    ssr: false,
  }
);
const ServiceSection = () => {
  const [activeTab, setActiveTab] = useState("ship")
  const serviceTabs = [
    {
      id:1,
      title: 'Ship',
      key: 'ship',
      icon: <FastDeliveryIcon />
    },
    {
      id:2,
      title: 'Shop',
      key: 'shop',
      icon: <CartIcon />
    },
    {
      id:1,
      title: 'Trip',
      key: 'trip',
      icon: <TravelerIcon />
    }
  ]
  return (
    <section className="py-12 lg:py-[110px] relative -z-10 lg:z-10 min-h-[1237px]">
      <div className="container mx-auto ">
        <div className="text-center hidden lg:block">
          <h2 className="heading1">
            All You Need In <span className="text-gradient">One Place</span>
          </h2>
          <p className="text-[#1D1D1F] text-[15px] lg:text-[18px] font-plus_jakarta font-semibold">
            Just follow easy steps & enjoy.
          </p>
        </div>
        <div className="flex mt-10 w-full bg-[#E8F1FF] lg:bg-transparent lg:w-[60%] justify-center lg:px-4 mx-auto py-2  lg:rounded-[13px] my-12">
          <ul className="flex items-center gap-10 lg:gap-14">
            {serviceTabs?.map((tab)=>(
              <li
              onClick={()=>setActiveTab(tab?.key)}
              key={tab?.id}
              className={`place-tab-item ${activeTab === tab?.key && 'active'}`}
            >
              {tab?.icon} {tab?.title}
            </li>
            ))}

          </ul>
        </div>
        {activeTab === 'ship' && <div className="lg:flex justify-center lg:justify-between gap-[12%] mx-5">
          <div className="lg:flex items-center justify-center w-full lg:w-[50%] rounded-md min-h-[300px] bg-[#fafafa]">
            <ShipAnyWhereAnimation />
          </div>
          <div>
            <h4 className="section-title mb-0 lg:mb-4 mt-5 lg:mt-0">Easy and Fast</h4>
            <h3 className="heading3 tex-[#1D1D1F] lg:text-[#17176A]">
              Ship your next Item 
              in 3 easy steps
            </h3>
            <div className="py2 lg:py-5">
              <div className="flex gap-4 lg:gap-8 my-5 lg:items-center">
                <div>
                  <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                    <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">1</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Choose Destination
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Select From your location and drop location with deliver
                    address. Type your box weight, Item name.
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center gap-4 lg:gap-8 my-5">
                <div>
                  <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                    <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">2</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Make Payment
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Select your payment method based on your freedom to choice.
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center gap-4 lg:gap-8 my-5">
                <div>
                  <div>
                    <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                      <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">3</h5>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Enjoy!
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Label created then paste on the box and ask for pick up or
                    drop off nearest location. Enjoy!
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-10">
                <Button
                      size="md"
                      className="bg-[#1777FE] text-[18px] text-white
                hover:bg-blue-400 transition-all  rounded-[8px]
                duration-300 font-plus_jakarta font-normal py-7 w-full lg:hidden"
                    >
                      Ship now
                    </Button>
                <div className="hidden lg:flex gap-10 items-center">
                  <Button
                    size="lg"
                    className="bg-[#1777FE] text-[14px] lg:text-[18px] text-white
              hover:bg-blue-400 transition-all 
              duration-300 font-plus_jakarta font-normal py-7"
                  >
                    Try it free
                  </Button>
                  <Button
                    startContent={
                      <div
                        className="bg-gradient-to-tr
                from-[#FFA00075]
                  to-slate-50
                 rounded-full 
                  flex items-center justify-center relative"
                      >
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FFA00075] opacity-75"></span>
                        <PlayBtnIcon />
                      </div>
                    }
                    size="lg"
                    className="bg-white text-[14px] text-[#4F4F4F] 
              font-plus_jakarta font-normal py-7 rounded-[12px]"
                  >
                    See it in Action
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {activeTab === 'shop' && <div className="lg:flex justify-center lg:justify-between gap-[12%] mx-5">
          <div className="lg:flex items-center justify-center w-full lg:w-[50%] rounded-md min-h-[300px] bg-[#fafafa]">
            <ShipAnyWhereAnimation />
          </div>
          <div>
            <h4 className="section-title mb-0 lg:mb-4 mt-5 lg:mt-0">Easy and Fast</h4>
            <h3 className="heading3 tex-[#1D1D1F] lg:text-[#17176A]">
              Ship your next Item 
              in 3 easy steps
            </h3>
            <div className="py2 lg:py-5">
              <div className="flex gap-4 lg:gap-8 my-5 lg:items-center">
                <div>
                  <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                    <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">1</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Choose Destination
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Select From your location and drop location with deliver
                    address. Type your box weight, Item name.
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center gap-4 lg:gap-8 my-5">
                <div>
                  <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                    <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">2</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Make Payment
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Select your payment method based on your freedom to choice.
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center gap-4 lg:gap-8 my-5">
                <div>
                  <div>
                    <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                      <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">3</h5>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Enjoy!
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Label created then paste on the box and ask for pick up or
                    drop off nearest location. Enjoy!
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-10">
                <Button
                      size="md"
                      className="bg-[#1777FE] text-[18px] text-white
                hover:bg-blue-400 transition-all  rounded-[8px]
                duration-300 font-plus_jakarta font-normal py-7 w-full lg:hidden"
                    >
                      Ship now
                    </Button>
                <div className="hidden lg:flex gap-10 items-center">
                  <Button
                    size="lg"
                    className="bg-[#1777FE] text-[14px] lg:text-[18px] text-white
              hover:bg-blue-400 transition-all 
              duration-300 font-plus_jakarta font-normal py-7"
                  >
                    Try it free
                  </Button>
                  <Button
                    startContent={
                      <div
                        className="bg-gradient-to-tr
                from-[#FFA00075]
                  to-slate-50
                 rounded-full 
                  flex items-center justify-center relative"
                      >
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FFA00075] opacity-75"></span>
                        <PlayBtnIcon />
                      </div>
                    }
                    size="lg"
                    className="bg-white text-[14px] text-[#4F4F4F] 
              font-plus_jakarta font-normal py-7 rounded-[12px]"
                  >
                    See it in Action
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {activeTab === 'trip' && <div className="lg:flex justify-center lg:justify-between gap-[12%] mx-5">
          <div className="lg:flex items-center justify-center w-full lg:w-[50%] rounded-md min-h-[300px] bg-[#fafafa]">
            <ShipAnyWhereAnimation />
          </div>
          <div>
            <h4 className="section-title mb-0 lg:mb-4 mt-5 lg:mt-0">Easy and Fast</h4>
            <h3 className="heading3 tex-[#1D1D1F] lg:text-[#17176A]">
             Enjoy! Track any time anywhere your products
            </h3>
            <div className="py2 lg:py-5">
              <div className="flex gap-4 lg:gap-8 my-5 lg:items-center">
                <div>
                  <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                    <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">1</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Enjoy!
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                    Input order ID or tracking number to the search bar
                  </p>
                </div>
              </div>
              <div className="flex lg:items-center gap-4 lg:gap-8 my-5">
                <div>
                  <div className="w-[24px] lg:w-[45px] h-[24px] lg:h-[45px] rounded-full bg-[#FFB607] flex items-center justify-center">
                    <h5 className="tex-[12px] lg:text-[16px] text-white font-volkhov">2</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-[14px] lg:text-[18px] text-[#1D1D1F] font-plus_jakarta font-medium lg:font-bold pb-1 lg:pb-2">
                    Results 
                  </h4>
                  <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-semibold">
                   Get your percal on the tracking bar.
                  </p>
                </div>
              </div>
              
              <div className="flex justify-center mt-10">
                <Button
                      size="md"
                      className="bg-[#1777FE] text-[18px] text-white
                hover:bg-blue-400 transition-all  rounded-[8px]
                duration-300 font-plus_jakarta font-normal py-7 w-full lg:hidden"
                    >
                      Ship now
                    </Button>
                <div className="hidden lg:flex gap-10 items-center">
                  <Button
                    size="lg"
                    className="bg-[#1777FE] text-[14px] lg:text-[18px] text-white
              hover:bg-blue-400 transition-all 
              duration-300 font-plus_jakarta font-normal py-7"
                  >
                    Try it free
                  </Button>
                  <Button
                    startContent={
                      <div
                        className="bg-gradient-to-tr
                from-[#FFA00075]
                  to-slate-50
                 rounded-full 
                  flex items-center justify-center relative"
                      >
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#FFA00075] opacity-75"></span>
                        <PlayBtnIcon />
                      </div>
                    }
                    size="lg"
                    className="bg-white text-[14px] text-[#4F4F4F] 
              font-plus_jakarta font-normal py-7 rounded-[12px]"
                  >
                    See it in Action
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      <MarketPlaces />
    </section>
  );
};

export default ServiceSection;

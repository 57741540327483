"use client";
import { Button } from "@nextui-org/react";
import Image from "next/image";
import React from "react";
import pickupVan from "@/public/images/pickup_van.png";
import { RxTriangleRight } from "react-icons/rx";
const ShipForMeSection = () => {
  return (
    <section className="bg-[#E8F1FF] lg:min-h-[500px] flex justify-center relative overflow-hidden">
       <div className="absolute right-0 top-0 w-[50%] hidden lg:block">
        <Image src={pickupVan} alt="woow" objectFit="cover" className="w-full max-h-[500px]"   />
       </div>
      <div className="container my-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 overflow-hidden">
          <div className="flex items-center p-4">
            <div className="mt-5 lg:mt-14">
              <h5 className="text-[#1777FE] text-[14px] font-poppins">
                Ship for me
              </h5>
              <h3 className="heading3 text-[#17176A] relative title-down-border-sm  lg:title-down-border after:w-[15%] lg:after:w-[20%] lg:my-3">
                Start <span className="text-gradient">Shipping</span> in Minutes
              </h3>
              <p className="text-[12px] lg:text-[16px] text-[#545454] font-plus_jakarta font-medium my-5 lg:my-8">
                Already at scale? Our shipping and logistics experts can
                <br />
                build the perfect global logistics setup for your needs.
              </p>
              <a href="" className="flex items-center gap-3 text-[12px] lg:text-[14px] text-[#545454] font-plus_jakarta font-medium my-5 lg:my-8">
                See more <RxTriangleRight /></a>
              <Button
                size="lg"
                className="bg-[#1777FE] text-[12px] lg:text-[14px] w-[150px] text-white font-poppins rounded-[8px] lg:my-5 hover:bg-blue-600"
              >
                GET STARTED
              </Button>
            </div>
          </div>
          <div className="order-first lg:hidden">
          <Image src={pickupVan} alt="woow" objectFit="cover" className="w-full h-full"   />
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipForMeSection;

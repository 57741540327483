import React from "react";

const PlayBtnIcon = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.3916" cy="19.3916" r="19.3916" fill="#F2994A" />
      <path
        d="M27.4622 18.6134C28.0614 18.9594 28.0614 19.8244 27.4622 20.1704L16.0307 26.7704C15.4315 27.1164 14.6824 26.6839 14.6824 25.9919L14.6824 12.792C14.6824 12.1 15.4315 11.6675 16.0307 12.0135L27.4622 18.6134Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayBtnIcon;

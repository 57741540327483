export const QuoteIcon = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.895 11.631V8.277C7.895 6.171 8.389 4.455 9.377 3.129C10.391 1.777 11.886 0.892998 13.862 0.476999V3.012C12.978 3.22 12.276 3.61 11.756 4.182C11.236 4.728 10.898 5.391 10.742 6.171H13.082V11.631H7.895ZM0.0170003 11.631V8.277C0.0170003 6.171 0.511 4.455 1.499 3.129C2.513 1.777 3.995 0.892998 5.945 0.476999V3.012C5.061 3.22 4.359 3.61 3.839 4.182C3.319 4.728 2.981 5.391 2.825 6.171H5.165V11.631H0.0170003Z"
      fill="#1777FE"
    />
  </svg>
);

import React from "react";
import { HiPlus } from "react-icons/hi";
import { motion, AnimatePresence } from "framer-motion";
import { BiMinus } from "react-icons/bi";
const Accordionitem = ({ open, toggle, title, description }: any) => {
  return (
    <>
      <div className="px-3 py-2">
        <div
          className={`flex items-center justify-between bg-[#D1E4FF] px-3 py-5 cursor-pointer  ${open?"rounded-t-lg":"rounded-lg"}`}
          onClick={toggle}
        >
          <h5 className="text-[14px] font-poppins text-[#1D1D1F] ">
            {title}
          </h5>
          <div className="text-[#1777FE]">
            {open ? <BiMinus /> : <HiPlus className="cursor-pointer" />}
          </div>
        </div>
        <AnimatePresence initial={false}>
          {open && (
            <motion.div
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              className="px-3 border-1 border-[#1777FE] rounded-b-lg"
            >
              <p className="text-[12px] lg:text-[14px] text-[#1D1D1F] font-poppins py-5">
                {description}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Accordionitem;
